<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-clock" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.tempo_medio_classificacao }} Minuto(s)
              </h2>
              <span>Tempo Médio de Espera</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-clock" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.tempo_medio_atendimento }} Minuto(s)
              </h2>
              <span>Tempo Médio de Atendimento</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.atendimentos }}
              </h2>
              <span>Atendimentos Recebidos</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.atendimentos_fila }}
              </h2>
              <span>Atendimentos Na Fila</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.atendimentos_finalizados }}
              </h2>
              <span>Atendimentos Finalizados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.atendimentos_cancelados }}
              </h2>
              <span>Atendimentos Cancelados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-primary`" size="45">
              <feather-icon size="21" :icon="'UsersIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.users }}
              </h2>
              <span>Usuários Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <i class="fas fa-building" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.companies }}
              </h2>
              <span>Empresas Cadastradas</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="45">
              <feather-icon size="21" :icon="'TagIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.specialties }}
              </h2>
              <span>Especialidades Cadastradas</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <feather-icon size="21" :icon="'ArchiveIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.setores }}
              </h2>
              <span>Setores Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.protocols }}
              </h2>
              <span>Protocolos Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-stethoscope" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.flowchart }}
              </h2>
              <span>Fluxogramas Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      dashboard: {
        highlights: {
          users: 0,
          companies: 0,
          specialties: 0,
          setores: 0,
          flowchart: 0,
          atendimentos_finalizados: 0,
          atendimentos_fila: 0,
          atendimentos: 0,
          protocols: 0,
          tempo_medio_classificacao: 0,
          tempo_medio_atendimento: 0,
        },
      },
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "dashboard";

      axios
        .post(api)
        .then((response) => {
          self.dashboard = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    self.getDashboard();
  },
  created() {},
};
</script>

<style>
</style>
